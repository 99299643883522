import { FileRealType, FormType, Tab, agentCategory } from "@/enums"
import { FormT, StoreT } from "@/types"
import { baseRuleValidators, customValidators } from "@/helpers/tools";
import lanSetting from "@/helpers/lanHelper"
import { h } from "vue";

export default ((tab: Tab, state: StoreT.State) => {
    const agentSelect = state?.tableLayout?.agentSelect ?? []
    const manufacturerValue = state?.form.formState.adminAgentUploadForm?.manufacturer
    const currentManufacturerValue = manufacturerValue?.[1] || manufacturerValue?.[0]
    return ({
        title: tab === Tab.create ? lanSetting.get("formConfig", "newAgentTitle") : lanSetting.get("formConfig", "upgradeAgentTitle"),
        width: 700,
        form: {
            name: "adminAgentUploadForm",
            layout: "inline",
            useStoreFormState: true,
            formTitle: h(
                'div',
                {
                    style: {
                        background: "rgba(0, 117, 255, 0.3)",
                        padding: "14px 10px",
                        display: "flex",
                        flexDirection: "column",
                        borderLeft: "3px solid rgb(0, 117, 255)",
                        lineHeight: 2
                      }
                },
                [
                    h('span', { style: { fontWeight: "bold" } }, "Naming pattern"),
                    h('span', { style: {  } }, [h('span', { style: { fontWeight: "bold" } }, "Agent:"), " SafeUEM Manufaturer Agent Signature-release-version"]),
                    h('span', { style: {  } }, [h('span', { style: { fontWeight: "bold" } }, "Service only:"), " SafeUEM Manufacturer Service App Signature-release-version"]),
                    h('span', { style: {  } }, [h('span', { style: { fontWeight: "bold" } }, "UEM only:"), " SafeUEM Manufacturer UEM App-release-version"]),
                    h('span', { style: {  } }, [h('span', { style: { fontWeight: "bold" } }, "Remote control:"), " SafeUEM Manufacturer RC Signature-release-version"])
                ]
              ),
            formConfig: [ {
                    caption: lanSetting.get("formConfig", "file"),
                    dataIndex: "file",
                    disabled: true,
                    rule: (value) => {
                        console.log(value)
                        if(value.file?.[0] || tab === Tab.create) return [customValidators.emptyFile, customValidators.fileType(FileRealType.apk)]

                        return []
                    },
                    dataType: FormType.file,
                    required: tab === Tab.create,
                    uploadNow: true,
                    width: "100%",
                },{
                    caption: lanSetting.get("formConfig", "fileName"),
                    dataIndex: "name",
                    required: true,
                    dataType: FormType.input,
                    width: "100%"
                }, {
                    caption: lanSetting.get("formConfig", "packageName"),
                    dataIndex: "packageName",
                    required: true,
                    disabled: true,
                    dataType: FormType.input,
                    width: "100%"
                },{
                    caption: lanSetting.get("tableConfig", "Manufacturer"),
                    dataIndex: "manufacturer",
                    required: true,
                    dataType: FormType.selectInput,
                    dynEnumParams: agentSelect.map(item => { return { text: item.name, value: item.name } }),   //动态params
                    disabled: tab === Tab.detail,
                    rule: () => [customValidators.onlyNumberPointLetters],
                    width: "100%"
                }, {
                    caption: lanSetting.get("tableConfig", "Model"),
                    dataIndex: "model",
                    required: true,
                    dataType: FormType.selectInput,
                    dynEnumParams: agentSelect.filter(item => item.name === currentManufacturerValue)[0]?.models.map(item => { return { text: item, value: item } }),  //动态params 根据manufacturer选择的值来筛选
                    disabled: tab === Tab.detail,
                    rule: () => [customValidators.onlyNumberPointCommaLetters],
                    width: "100%",
                    tips: "<span>*</span>" + lanSetting.get("formConfig", "modelTips")
                }, {
                    caption: lanSetting.get("formConfig", "version"),
                    dataIndex: "version",
                    required: true,
                    disabled: true,
                    width: "100%",
                    dataType: FormType.input
                }, {
                    caption: lanSetting.get("formConfig", "category"),
                    dataIndex: "category",
                    required: true,
                    width: "100%",
                    disabled: tab === Tab.detail,
                    dataType: FormType.enum,
                    enumParams: agentCategory
                }, {
                    caption: lanSetting.get("formConfig", "signature"),
                    dataIndex: "signature",
                    required: true,
                    dataType: FormType.selectInput,
                    dynEnumParams: agentSelect.filter(item => item.name === currentManufacturerValue)[0]?.signatures.map(item => { return { text: item, value: item } }),  //动态params 根据manufacturer选择的值来筛选
                    width: "100%",
                    rule: () => [customValidators.onlyNumberLetters],
                    tips: "<span>*</span>" + lanSetting.get("formConfig", "signatureTips")
                }, {
                    caption: lanSetting.get("formConfig", "remark"),
                    dataIndex: "remark",
                    dataType: FormType.textArea,
                    width: "100%",
                    rows: 9,
                    rule: () => [baseRuleValidators.max(256)],
                }],
            submitWidth: "100%",
            submitText: tab === Tab.detail ? lanSetting.get("public", "upgrade"): ""
        }

    })
}) as FormT.FormLayOutConfig