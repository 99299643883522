import enums, { enumVal } from "@/enums"
import { RouteRecordRaw, RouteComponent } from "vue-router"
import { MainT } from "@/types"
import lanHelper from "@/helpers/lanHelper"

const getPages = async (_theme: enumVal.UserTypesVal) => {
	if (_theme === enums.UserTypes.partner)
		return {
			containers: (await import("@/portal/containers/partner/index")).default,
		}
	if (_theme === enums.UserTypes.admin)
		return {
			containers: (await import("@/portal/containers/admin/index")).default,
		}
	if (_theme === enums.UserTypes.ae)
		return {
			containers: (await import("@/portal/containers/ae/index")).default,
		}
	return {
		containers: (await import("@/portal/themes/login")).default,
	}
}

const pathFilter = (paths: MainT.PathConfig[], userType: enumVal.UserTypesVal): MainT.PathConfig[] => {
	const toFilter = (_P: MainT.PathConfig[]) => {
		return _P.filter((item) => {
			if ("userType" in item && item.userType !== userType) return false
			if ("children" in item) item.children = toFilter(item.children || [])
			return true
		})
	}
	return pathComplete(toFilter(paths))
}

const pathComplete = (paths: MainT.PathConfig[], parent?: string): MainT.PathConfig[] => {
	return paths.map((_p, index) => {
		const key = [parent, index].filter((item) => item !== undefined).join("-")
		return {
			..._p,
			...{
				key,
				title: _p.lanItem ? lanHelper.get("pageTree", _p.lanItem) : ""
			},
			...(_p.children ? { children: pathComplete(_p.children, key) } : {}),
		}
	})
}

const getPathParentKey = (key: string) => {
	return key
		.split("-")
		.reduce((a, c, i) => {
			const _a = a.split(",").filter((item) => item !== "")
			return (i === 1 ? a + "," : "") + (_a.length > 1 ? a + "," + _a[_a.length - 1] + "-" + c : a + "-" + c + "")
		})
		.split(",")
}

const getTargetPath = (key: string, paths: MainT.PathConfig[]): MainT.PathConfig => {
	return key
		.split("-")
		.reduce((a: any, c) => {
			return (typeof a === "string" ? paths[parseInt(a)] : a).children[parseInt(c)]
		}) as MainT.PathConfig
}

const getRoute = (paths: MainT.PathConfig[], pages: Record<string, RouteComponent>): RouteRecordRaw[] => {
	const toRoutes = (_P: MainT.PathConfig[]): any => {
		return _P
			.map((item) => {
				const _c = item.children ? toRoutes(item.children) : []
				if ("path" in item)
					return {
						...{
							name: item.key,
							key: item.key,
							path: item.path,
							component: pages[item.page as string],
						},
						...(_c.length ? { children: _c } : {}),
					}
				if (_c.length) return _c
				return []
			})
			.flat(1)
	}
	return toRoutes(paths)
}

const styleSetting = (colors: Record<string, string>) => {
	const style = document.createElement("style"),
		head = document.head
	const vars = Object.entries(colors)
		.map((el) => `--${el[0]}-color: rgb(${el[1]});--${el[0]}: ${el[1]};`)
		.join("")
	const classNames = Object.entries(colors)
		.map((el) => `.colors-${el[0]}-bac { background: rgb(${el[1]}) } .colors-${el[0]}-color { color: rgb(${el[1]}) }`)
		.join("")
	let scale = (window.innerWidth * window.devicePixelRatio) / 1920
	scale = scale < 1 ? 1 : scale

	style.innerHTML = `
			:root { ${vars} }
			${classNames}
			html{font-size: ${(10 / window.devicePixelRatio) * scale}px}
		`
	head.appendChild(style)
}

const mixSort = (elementA: string, elementB: string) => {
	const regDigit = /^\d+$/;
    const regChinese = /[\u4e00-\u9fa5]/; // Unicode range for Chinese characters

    // Ensure both elements are strings
    const a = String(elementA);
    const b = String(elementB);

    const isDigitA = regDigit.test(a);
    const isDigitB = regDigit.test(b);
    const hasChineseA = regChinese.test(a);
    const hasChineseB = regChinese.test(b);

    if (hasChineseA && !hasChineseB) {
        // A has Chinese, it comes after B
        return 1;
    } else if (!hasChineseA && hasChineseB) {
        // B has Chinese, it comes after A
        return -1;
    } else if (isDigitA && isDigitB) {
        // Both are pure digits, compare them numerically
        return Number(a) - Number(b);
    } else if (isDigitA) {
        // A is pure digit, it comes first
        return -1;
    } else if (isDigitB) {
        // B is pure digit, it comes first
        return 1;
    } else {
        // Both contain letters and/or digits
        const letterComparison = a.localeCompare(b, undefined, { sensitivity: 'base' });
        
        if (letterComparison === 0) {
            // If letters are the same, compare them by the numbers in the strings
            const numA = parseInt(a.match(/\d+/)?.[0] ?? "", 10);
            const numB = parseInt(b.match(/\d+/)?.[0] ?? "", 10);
            return numA - numB;
        } else {
            return letterComparison;
        }
    }
}

export default {
	getPages,
	pathFilter,
	getRoute,
	styleSetting,
	getPathParentKey,
	getTargetPath,
	mixSort
}
