import { FormType } from "@/enums"
import lanSetting from "@/helpers/lanHelper"
import { FormT } from "@/types"

export default (() => ({
    title: lanSetting.get("formConfig", "createAE"),
    form: {
        name: "aeCreateForm",
        formConfig: [
            {
                caption: lanSetting.get("tableConfig", "customer_name"),
                dataIndex: "customer_name",
                dataType: FormType.input,
                required: true
            },
            {
                caption: lanSetting.get("tableConfig", "domain"),
                dataIndex: "domain",
                dataType: FormType.input,
                required: true
            }
        ],
        hiddenCancelBtn: true
    }

})) as FormT.FormLayOutConfig