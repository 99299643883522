import api from "@/api"
import { message } from "ant-design-vue"
import { FormT, StoreT } from "@/types"
import { getCaptcha } from "../themes/components/VerificationCode/VerificationCode"
import { handleErrMeg } from "@/helpers/httpHelper"

export const defaultState: StoreT.LoginState = {
  captchaId: "",
  enable: true,
  isSucess: false,
  loginInfo: {}
}

export default {
  state: JSON.parse(JSON.stringify(defaultState)),
  namespace: true,
  mutations: {
    ["SET_CAPTCHA_ID"](state: StoreT.LoginState, payload: string) {
      state.captchaId = payload
    },
    ["SET_LOGIN_INFO"](state: StoreT.LoginState, payload: StoreT.LoginState["loginInfo"]) {
      state.loginInfo = payload
    },
    ["RESET_PASSWORD_IS_SUCESS"](state: StoreT.LoginState, payload: boolean) {
      state.isSucess = payload
    },
  },
  actions: {
    ["LOGIN_SUBMIT"](this: StoreT.Instance, injectee: StoreT.ActionContext, payload: FormT.FormPayload.loginForm) {
      this.state.form.loading = true
      api.login.postLogin({ ...payload, captchaId: this.state.login.captchaId }).then(res => {
        if (res.code !== 0) {
          handleErrMeg(res.msg)
          getCaptcha()
          if(res.msg === "User is prohibited from logging in"){
            this.state.login.enable = false            
          }
          this.state.form.loading = false
        } else {
          localStorage.setItem("token", res.data.token)
          window.location.reload()
        }
      })
    },
    ["ACCOUNT_SUBMIT"](this: StoreT.Instance, injectee: StoreT.ActionContext, payload: FormT.FormPayload.accountForm) {
      api.login.postAccount({ ...payload, captchaId: this.state.login.captchaId }).then(res => {
        if(res.code === 0){
          message.success({ content: 'Submitted successfully'})
          setTimeout(() => { window.location.reload() }, 2000)
        } else {
          handleErrMeg(res.msg)
        } 
      })
    }
  },
}
