import { FormType } from "@/enums"
import { FormT } from "@/types"
import { customValidators } from "@/helpers/tools";
import lanSetting from "@/helpers/lanHelper";


export default (() => ({
  title: lanSetting.get("formConfig", "password"),
  form: {
    name: "passwordForm",
    formConfig:
      [{
        caption: lanSetting.get("formConfig", "currentPassword"),
        dataIndex: "password",
        required: true,
        dataType: FormType.password,
      }, {
        caption: lanSetting.get("formConfig", "newPassword"),
        dataIndex: "newPassword",
        required: true,
        dataType: FormType.password,
        rule: () => [customValidators.passwordLength, customValidators.passwordRegexTrue, customValidators.passwordIncludeAccount, customValidators.passwordContinuous],
      }, {
        caption: lanSetting.get("formConfig", "confirmPassword"),
        dataIndex: "confirmPassword",
        required: true,
        dataType: FormType.password,
        rule: (formValue) => {
          return [customValidators.samePassword(formValue, "newPassword")]
        },
      }]
  }

})) as FormT.FormLayOutConfig