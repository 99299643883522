import { store } from "@/portal/store"
import api from "@/api"
import { StoreT, MainT, FormT } from "@/types"
import { enumVal, PartnerStatusAction, Tab } from "@/enums"
import { WebLogger, actionModal, downloadFileByFetch, downloadFileByStream, downloadFileByUrl } from "@/helpers/tools"
import { RouteLocationNormalized } from "vue-router"
import lanSetting from "@/helpers/lanHelper"
import fileUploadHelper from "@/helpers/fileUploadHelper"
import { message } from "ant-design-vue"
import { handleErrMeg } from "@/helpers/httpHelper"
import { selectItem } from "../themes/components/ButtonGroup"
import { h } from "vue"
import moment from "moment"

export const defaultState: StoreT.TableLayoutState = {
	statistic: {},
	logs: [],
	list: {
		records: [],
		total: 0,
	},
	selectedRowKeys: [],
	searchConditions: {
		currentIndex: 0,
		currentCategory: "",
	},
	currentDetails: {},
	agentSelect: [],
	selectConfigs: {
		agent: {
			Manufacturer: {
				select: "",
				option: []
			},
			Model: {
				select: "",
				option: []
			}
		}
	}

}

export default {
	state: JSON.parse(JSON.stringify(defaultState)),
	namespace: true,
	mutations: {
		["SET_STATISTIC"](state: StoreT.TableLayoutState, payload: StoreT.TableLayoutState["statistic"]) {
			state.statistic = Object.assign({}, state.statistic, payload)
		},
		["SET_LOGS"](state: StoreT.TableLayoutState, payload: StoreT.TableLayoutState["logs"]) {
			state.logs = payload
		},
		["SET_TABLELAYOUT_LIST"](state: StoreT.TableLayoutState, payload: Pick<StoreT.TableLayoutState, "list" | "searchConditions">) {
			state.list = payload.list
			state.searchConditions = payload.searchConditions
		},
		["RESET_TABLELAYOUT_LIST"](state: StoreT.TableLayoutState) {
			state.list = defaultState.list
			state.searchConditions = defaultState.searchConditions
		},
		["SET_SELECTED_ROW_CHANGE"](state: StoreT.TableLayoutState, payload: number[]) {
			state.selectedRowKeys = payload
		},
		["SET_RECENTLY_UPDATE"](state: StoreT.TableLayoutState, payload: StoreT.recentlyUpdateItem[]) {
			state.recentlyUpdate = payload
		},
		["SET_CURRENT_DETAILS"](state: StoreT.TableLayoutState, payload: Record<string, any>) {
			state.currentDetails = payload
		},
		["SWITCHPAGE"](state: StoreT.TableLayoutState, payload: RouteLocationNormalized) {
			state.selectedRowKeys = []
		},
		["CHANGE_CURRENT_CATEGORY"](state: StoreT.TableLayoutState, payload: number | string) {
			state.searchConditions.currentCategory = payload
		},
		["CHANGE_SELECT"](state: StoreT.TableLayoutState, payload: Record<string, any>) {
			const { page, label, value } = payload
			state.selectConfigs[page][label].select = value
		},
		["SET_AGENT_SELECT"](state: StoreT.TableLayoutState, payload: StoreT.TableLayoutState["agentSelect"]) {
			state.agentSelect = payload
		},
		["SET_MANUFACTURER"](state: StoreT.TableLayoutState, payload: { options: selectItem[] }) {
			const { options } = payload
			state.selectConfigs.agent.Manufacturer.text = lanSetting.get("tableConfig", "Manufacturer")
			state.selectConfigs.agent.Model.text = lanSetting.get("tableConfig", "Model")
			state.selectConfigs.agent.Manufacturer.option = [...[{ text: '-', value: '' }], ...options]
		},
		["SET_MODEL"](state: StoreT.TableLayoutState, payload: { options: selectItem[] }) {
			const { options } = payload
			if (!options) return
			state.selectConfigs.agent.Model.option = [...[{ text: '-', value: '' }], ...options]
		},
		["CHANGE_DIALOGUE"](state: StoreT.TableLayoutState, payload: StoreT.SystemSate["dialogueConfig"]) {
			if (!payload) {
				state.currentDetails = {}
			}
		},
	},
	actions: {
		["GET_STATISTIC"](this: StoreT.Instance, injecte: StoreT.ActionContext) {
			api.partner.statistics().then(res => {
				if (res.code === 0) {
					store.commit("SET_STATISTIC", {
						status: [res.data.all ? res.data.all : 0, res.data.pending ? res.data.pending : 0, res.data.active ? res.data.active : 0]
					})
				} else {
					handleErrMeg(res.msg)
				}
			})
		},
		["GET_ACTIVE_REGION"](this: StoreT.Instance, injecte: StoreT.ActionContext) {
			api.partner.activeRegion().then(res => {
				if (res.code === 0) {
					let regions = []

					for (let key in res.data) {
						let obj: { value: number, name: string } = { value: 0, name: "" }
						obj.value = res.data[key]
						obj.name = lanSetting.get("enums", "Region" + key)
						regions.push(obj)
					}
					store.commit("SET_STATISTIC", { regions })
				} else {
					handleErrMeg(res.msg)
				}
			})
		},
		["GET_LOGS"](this: StoreT.Instance, injectee: StoreT.ActionContext) {
			api.user.getLog(1, 10).then(res => {
				if (res.code === 0) {
					store.commit("SET_LOGS", res.data.list ?? [])
				} else {
					handleErrMeg(res.msg)
				}

			})
		},
		["GET_TABLELAYOUT_LIST"](this: StoreT.Instance, injecte: StoreT.ActionContext, payload: Pick<StoreT.TableLayoutState, "searchConditions">) {
			const { currentPage } = this.state.system
			const { searchConditions, selectConfigs } = this.state.tableLayout
			const _p = {
				...searchConditions,
				...payload,
			}
			store.commit("SET_TABLELAYOUT_LIST", { list: { data: [], total: 0 }, searchConditions: _p })
			switch (currentPage?.page) {
				case "AdminPartner":
					store.dispatch("GET_PARTNER_LIST", _p)
					break
				case "AdminSalesInfo":
					store.dispatch("GET_SALESINFO_LIST", _p)
					break
				case "AdminTraining":
					store.dispatch("GET_ADMINTRAININGLIST", _p)
					break
				case "AdminMaterials":
					store.dispatch("GET_MATERIALS_LIST", _p)
					break
				case "PartnerMarketingMaterials":
					store.dispatch("GET_PARTNER_MATERIALS_LIST", _p)
					break
				case "PartnerTraining":
					store.dispatch("GET_PARTNER_TRAINING", _p)
					break
				case "AdminStatistics":
					store.dispatch("GET_STATISTICS", _p)
					break
				case "AdminAgent":
					store.dispatch("GET_AGENT_LIST", {
						..._p, ...{
							currentSelectConfig: selectConfigs[currentPage.tableLayout || ""]
						}
					})
					break
				case "PartnerAgent":
					store.dispatch("GET_PARTNER_AGENT_LIST", {
						..._p, ...{
							currentSelectConfig: selectConfigs[currentPage.tableLayout || ""]
						}
					})
					break
				case "AETable": {
					store.dispatch("GET_AETable_LIST", {
						..._p, ...{
							currentSelectConfig: selectConfigs[currentPage.tableLayout || ""]
						}
					})
					store.dispatch("GET_USER_INFO")
					if (typeof (EventSource) !== "undefined") {
						let obj = [
							"begin create project",
							"project created",
							"begin enable APIs",
							"APIs enabled",
							"begin create service account",
							"service account created",
							"begin binding service account",
							"service account binding success"
						]


						// EventSource对象可用
						var source = new EventSource(`${window.globalConfig.apiBase}/sse`);

						source.addEventListener("process", (event) => {
							WebLogger.log("收到消息: ", event.data);

							obj.map((item, index) => {
								if (item === event.data || item.indexOf(obj[0]) >= 0) {
									store.commit("SET_IS_LOADINH_UPLOAD", true)

									const process = Math.round(((index + 1) / obj.length) * 100)
									store.commit("SET_LOADING_UPLOAD_PROGRESS", process)

									if (process === 100) {
										store.commit("SET_IS_LOADINH_UPLOAD", false)
										store.commit("CHANGE_DIALOGUE")
										store.commit("SET_LOADING_UPLOAD_PROGRESS", 0)
									}
								}
							})
						})

						source.addEventListener("logout",(event)=>{
							location.href = "/#/login"
						})
						source.onopen = function (event) {
							WebLogger.log("连接打开")
						};
						source.onmessage = function (event) {
							WebLogger.log("收到消息: ", event.data);
						};
						source.onerror = function (event) {
							WebLogger.log("发生错误", event);
						};
					} else {
						// EventSource对象不可用
						WebLogger.log("浏览器不支持SSE");
					}
				}
					break
			}
		},
		["GET_PARTNER_LIST"](this: StoreT.Instance, injectee: StoreT.ActionContext, payload: StoreT.TableLayoutState["searchConditions"]) {
			api.partner.getList(10, payload.currentIndex, payload.currentCategory as enumVal.PartnerStatusVal).then(res => {
				if (res.code === 0) {
					store.commit("SET_TABLELAYOUT_LIST", {
						list: res.data,
						searchConditions: payload,
					})
				} else {
					handleErrMeg(res.msg)
				}
			})
		},
		["GET_SALESINFO_LIST"](this: StoreT.Instance, injectee: StoreT.ActionContext, payload: StoreT.TableLayoutState["searchConditions"]) {
			api.partner.getSaleList(10, payload.currentIndex, payload.currentCategory as string).then((res) => {
				if (res.code === 0) {
					store.commit("SET_TABLELAYOUT_LIST", {
						list: res.data,
						searchConditions: payload,
					})
				} else {
					handleErrMeg(res.msg)
				}
			})
		},
		["GET_MATERIALS_LIST"](this: StoreT.Instance, injectee: StoreT.ActionContext, payload: StoreT.TableLayoutState["searchConditions"]) {
			api.market.getList(10, payload.currentIndex, payload.currentCategory as string).then(res => {
				if (res.code === 0) {
					store.commit("SET_TABLELAYOUT_LIST", {
						list: res.data,
						searchConditions: payload,
					})
				} else {
					handleErrMeg(res.msg)
				}
			})
		},
		["PARTNER_OPERATIONS"](this: StoreT.Instance, injectee: StoreT.ActionContext, payload: { type: enumVal.PartnerStatusActionVal } & MainT.tableActionPayload) {
			switch (payload.type) {
				case PartnerStatusAction.disable: {
					actionModal({
						title: lanSetting.get("public", "confirmDisableTitle"),
						content: lanSetting.get("public", "confirmDisable"),
						apiFunction: () => api.partner.partnerAction(payload.record.record.uuid, PartnerStatusAction.disable),
						callback: () => { store.dispatch("GET_PARTNER_LIST", { currentIndex: 1, currentCategory: this.state.tableLayout.searchConditions.currentCategory }) }
					})
				}
					break
				case PartnerStatusAction.reject: {
					actionModal({
						title: lanSetting.get("public", "confirmRejectTitle"),
						content: lanSetting.get("public", "confirmReject"),
						apiFunction: () => api.partner.partnerAction(payload.record.record.uuid, PartnerStatusAction.reject),
						callback: () => { store.dispatch("GET_PARTNER_LIST", { currentIndex: 1, currentCategory: this.state.tableLayout.searchConditions.currentCategory }) }
					})
				}
					break
				case PartnerStatusAction.approve: {
					actionModal({
						title: lanSetting.get("public", "confirmApproveTitle"),
						content: lanSetting.get("public", "confirmApprove"),
						apiFunction: () => api.partner.partnerAction(payload.record.record.uuid, PartnerStatusAction.approve),
						callback: () => { store.dispatch("GET_PARTNER_LIST", { currentIndex: 1, currentCategory: this.state.tableLayout.searchConditions.currentCategory }) }
					})
				}
					break
				case PartnerStatusAction.reactivate: {
					actionModal({
						title: lanSetting.get("public", "confirmReactivateTitle"),
						content: lanSetting.get("public", "confirmReactivate"),
						apiFunction: () => api.partner.partnerAction(payload.record.record.uuid, PartnerStatusAction.reactivate),
						callback: () => { store.dispatch("GET_PARTNER_LIST", { currentIndex: 1, currentCategory: this.state.tableLayout.searchConditions.currentCategory }) }
					})
				}
					break
			}
		},
		["GET_RECENTLY_UPDATE"](this: StoreT.Instance, injectee: StoreT.ActionContext, payload: null) {
			api.partner.getRecentlyUpdate().then(res => {
				if (res.code === 0) {
					store.commit("SET_RECENTLY_UPDATE", res.data.list.slice(0, 3))
				} else {
					handleErrMeg(res.msg)
				}
			})
		},
		["GET_CURRENT_DETAILS"](this: StoreT.Instance, injectee: StoreT.ActionContext, payload: MainT.tableActionPayload) {
			store.commit("SET_CURRENT_DETAILS", payload.record.record)
		},
		["SALE_ADD"](this: StoreT.Instance, injectee: StoreT.ActionContext, payload: FormT.FormPayload.adminSalesInformationUploadForm) {
			api.partner.addSale(payload).then(res => {
				if (res.code === 0) {
					store.commit("CHANGE_DIALOGUE")
					store.dispatch("GET_SALESINFO_LIST", { currentIndex: 1, currentCategory: this.state.tableLayout.searchConditions.currentCategory })
				} else {
					handleErrMeg(res.msg)
				}
			})
		},
		["TO_DELETE"](this: StoreT.Instance, injectee: StoreT.ActionContext, payload: MainT.tableActionPayload) {
			let name = "", callback, apiFunction: (() => Promise<unknown>) | undefined, content, title, okText = lanSetting.get("public", "delete")
			switch (this.state.system.currentPage?.page) {
				case "AdminSalesInfo": {
					name = "sale"
					title = lanSetting.get("public", "confirmDeleteSaleTitle")
					callback = () => {
						store.commit("SET_SELECTED_ROW_CHANGE", [])
						store.dispatch("GET_SALESINFO_LIST", { currentIndex: 1, currentCategory: this.state.tableLayout.searchConditions.currentCategory })
					}
				}
					break;
				case "AdminMaterials": {
					name = "market"
					title = lanSetting.get("public", "confirmDeleteMarketTitle")
					callback = () => {
						store.commit("SET_SELECTED_ROW_CHANGE", [])
						store.dispatch("GET_MATERIALS_LIST", { currentIndex: 1, currentCategory: this.state.tableLayout.searchConditions.currentCategory })
					}
				}
					break
				case "AdminTraining": {
					name = "training"
					title = lanSetting.get("public", "confirmDeleteTrainingTitle")
					callback = () => {
						store.commit("SET_SELECTED_ROW_CHANGE", [])
						store.dispatch("GET_ADMINTRAININGLIST", { currentIndex: 1, currentCategory: this.state.tableLayout.searchConditions.currentCategory })
					}
				}
					break
				case "AdminPartner": {
					name = "partner"
					title = lanSetting.get("public", "confirmDeletePartnerTitle")
					callback = () => {
						store.commit("SET_SELECTED_ROW_CHANGE", [])
						store.dispatch("GET_PARTNER_LIST", { currentIndex: 1, currentCategory: this.state.tableLayout.searchConditions.currentCategory })
					}
				}
					break
				case "AdminAgent": {
					name = "agent"
					title = lanSetting.get("public", "confirmDeleteAgentTitle")
					if (this.state.tableLayout.selectedRowKeys.length === 0) {
						content = h(
							'span',
							[
								lanSetting.get("public", "confirmDeleteAgent"),
								h('span', { style: { fontWeight: '800' } }, payload.record.record.name + "?")
							]
						)
					} else {
						content = h(
							'span',
							[
								lanSetting.get("public", "confirmDeleteAgent"),
								h('span', { style: { fontWeight: '800' } }, this.state.tableLayout.selectedRowKeys.length + " items ?")
							]
						)
					}

					apiFunction = () => api.agent.deleteAgent(this.state.tableLayout.selectedRowKeys)

					callback = async () => {
						store.commit("SET_SELECTED_ROW_CHANGE", [])

						const { selectConfigs } = this.state.tableLayout
						const { currentPage } = this.state.system

						store.commit("CHANGE_DIALOGUE")
						await store.dispatch("GET_MANUFACTURER")
						await store.dispatch("GET_MODEL", { value: selectConfigs[currentPage?.tableLayout || ""]?.Manufacturer.select })

						const ManufacturerValues = this.state.tableLayout.selectConfigs[currentPage?.tableLayout || ""]?.Manufacturer?.option?.map((item: any) => item.value)
						const ManufacturerSelect = this.state.tableLayout.selectConfigs[currentPage?.tableLayout || ""]?.Manufacturer?.select

						if (ManufacturerValues.indexOf(ManufacturerSelect) < 0) {  //如果Manufacturer被删除
							store.commit("CHANGE_SELECT", { label: "Model", value: "", page: "agent" })
							await store.dispatch("GET_MODEL", { value: "" })
							store.commit("CHANGE_SELECT", { label: "Manufacturer", value: "", page: "agent" })
						}

						const ModelValues = this.state.tableLayout.selectConfigs[currentPage?.tableLayout || ""]?.Model?.option?.map((item: any) => item.value)
						const ModelSelect = this.state.tableLayout.selectConfigs[currentPage?.tableLayout || ""]?.Model?.select

						if (ModelValues.indexOf(ModelSelect) < 0) {
							store.commit("CHANGE_SELECT", { label: "Model", value: "", page: "agent" })
						}

						store.dispatch("GET_AGENT_LIST", {
							currentCategory: this.state.tableLayout.searchConditions.currentCategory,
							currentSelectConfig: selectConfigs[currentPage?.tableLayout || ""]
						})


					}
				}
			}

			if (this.state.tableLayout.selectedRowKeys.length > 0) {
				actionModal({
					title: title,
					okText: okText,
					content: content || lanSetting.get("public", "confirmBuikDelete"),
					apiFunction: apiFunction || (() => api.partner.batchDelete(name, this.state.tableLayout.selectedRowKeys)),
					callback: callback
				})
			} else if (payload) {
				switch (this.state.system.currentPage?.page) {
					case "AdminSalesInfo": {
						apiFunction = () => api.partner.deleteSale(payload.record.record.ID)
					}
						break;
					case "AdminMaterials": {
						apiFunction = () => api.market.delete(payload.record.record.ID)
					}
						break
					case "AdminTraining": {
						apiFunction = () => api.training.delete(payload.record.record.ID)
					}
						break
					case "AdminPartner": {
						apiFunction = () => api.partner.deleteRejectedPartner(payload.record.record.uuid)
					}
						break
					case "AdminAgent": {
						apiFunction = () => api.agent.deleteAgent([payload.record.record.ID])
					}
						break
				}

				actionModal({
					title: title,
					okText: okText,
					content: content || lanSetting.get("public", "confirmDelete"),
					apiFunction: apiFunction,
					callback: callback
				})
			}

		},
		["TO_EDIT"](this: StoreT.Instance, injectee: StoreT.ActionContext, payload: MainT.tableActionPayload) {
			store.commit("SET_CURRENT_DETAILS", payload.record.record)
			switch (this.state.system.currentPage?.tableLayout) {
				case "agent":
					store.dispatch("TO_CHANGE_DIALOGUE", "agent")
					break
				case "partner":
					store.dispatch("TO_CHANGE_DIALOGUE", "account")
					break
			}
			store.commit("CHANGE_FORM_TAB", Tab.detail)
		},
		["TO_DOWNLOAD"](this: StoreT.Instance, injectee: StoreT.ActionContext, payload: MainT.tableActionPayload) {
			let url: () => string
			switch (this.state.system.currentPage?.page) {
				case "AETable": {
					url = () => `${window.globalConfig.apiBase}/project/export`
				}
					break;
			}
			const callApi = (fileName: string) => {
				url && downloadFileByFetch(url())
			}

			callApi("ajiao.csv")
		},
		async ["MARKET_UPLOAD"](this: StoreT.Instance, injectee: StoreT.ActionContext, payload: FormT.FormPayload.adminSalesMarketingUploadForm) {
			store.commit("SET_IS_LOADINH_UPLOAD", true)
			const { chunks, fileInfo } = await fileUploadHelper(payload.file[0].originFileObj)
			fileInfo.category = payload.category
			fileInfo.group = "marketing"
			fileInfo.name = payload.name

			const postChunk = async (startIndex: number) => {


				for (const [index, _chunk] of chunks.entries()) {
					if (index < startIndex) continue

					const formData = new FormData();

					formData.append('name', payload.name);
					formData.append('category', payload.category);
					formData.append('fileType', fileInfo.fileType);
					formData.append('fileName', fileInfo.fileName);
					formData.append('md5', fileInfo.md5);
					formData.append('chunkTotal', fileInfo.chunkTotal);
					formData.append('chunkMd5', _chunk.chunkMd5);
					formData.append('chunkNumber', String(index));
					formData.append('group', "marketing");

					formData.append('file', _chunk.file);

					await api.system.upload(formData).then(res => {
						if (res.code === 0) {

							// 进度条
							const process = Math.round(((index + 1) / Number(fileInfo.chunkTotal)) * 100)
							store.commit("SET_LOADING_UPLOAD_PROGRESS", process)
							if (process === 100) {
								store.commit("SET_IS_LOADINH_UPLOAD", false)
								store.commit("SET_LOADING_UPLOAD_PROGRESS", 0)
							}

						} else {
							handleErrMeg(res.msg)
							store.commit("SET_IS_LOADINH_UPLOAD", false)
							store.commit("CHANGE_DIALOGUE")
							store.dispatch("GET_MATERIALS_LIST", { currentIndex: 1, currentCategory: this.state.tableLayout.searchConditions.currentCategory })
						}
					})
				}

				await api.system.uploadFinish({
					md5: fileInfo.md5,
					fileName: fileInfo.fileName,
					category: payload.category
				})

				store.commit("CHANGE_DIALOGUE")
				store.dispatch("GET_MATERIALS_LIST", { currentIndex: 1, currentCategory: this.state.tableLayout.searchConditions.currentCategory })

			}


			api.system.fileFind(fileInfo)
				.then(res => {
					if (res.code === 0) {
						if (!res.data.file.isFinish) {
							const startIndex = res.data.file.sysFileChunks?.length
							postChunk(startIndex)
						} else {
							store.commit("SET_LOADING_UPLOAD_PROGRESS", 100)
							message.success(lanSetting.get("public", "uploadSuccess"))
							store.commit("SET_IS_LOADINH_UPLOAD", false)
							store.commit("CHANGE_DIALOGUE")
							store.dispatch("GET_MATERIALS_LIST", { currentIndex: 1, currentCategory: this.state.tableLayout.searchConditions.currentCategory })
						}


					} else {
						handleErrMeg(res.msg)
						store.commit("SET_IS_LOADINH_UPLOAD", false)
						store.commit("CHANGE_DIALOGUE")
						store.dispatch("GET_MATERIALS_LIST", { currentIndex: 1, currentCategory: this.state.tableLayout.searchConditions.currentCategory })

					}
				})
				.catch(err => {
					store.commit("SET_IS_LOADINH_UPLOAD", false)
					console.log(err)
				})

		},
		["GET_ADMINTRAININGLIST"](this: StoreT.Instance, injectee: StoreT.ActionContext, payload: StoreT.TableLayoutState["searchConditions"]) {
			api.training.getList(10, payload.currentIndex, payload.currentCategory as string).then(res => {
				if (res.code === 0) {
					store.commit("SET_TABLELAYOUT_LIST", {
						list: res.data,
						searchConditions: payload,
					})
				} else {
					handleErrMeg(res.msg)
				}
			})
		},
		async ["TRAINING_UPLOAD"](this: StoreT.Instance, injectee: StoreT.ActionContext, payload: FormT.FormPayload.adminTrainingUploadForm) {

			store.commit("SET_IS_LOADINH_UPLOAD", true)

			const { chunks, fileInfo } = await fileUploadHelper(payload.file[0].originFileObj)
			fileInfo.category = payload.category
			fileInfo.group = "training"
			fileInfo.name = payload.name

			const postChunk = async (startIndex: number) => {

				for (const [index, _chunk] of chunks.entries()) {
					if (index < startIndex) continue


					const formData = new FormData();

					formData.append('name', payload.name);
					formData.append('category', payload.category);
					formData.append('fileType', fileInfo.fileType);
					formData.append('fileName', fileInfo.fileName);
					formData.append('md5', fileInfo.md5);
					formData.append('chunkTotal', fileInfo.chunkTotal);
					formData.append('chunkMd5', _chunk.chunkMd5);
					formData.append('chunkNumber', String(index));
					formData.append('group', "training");

					formData.append('file', _chunk.file);

					await api.system.upload(formData).then(res => {
						if (res.code === 0) {

							// 进度条
							this.state.system.loadingUploadProgress = Math.round(((index + 1) / Number(fileInfo.chunkTotal)) * 100)
							if (this.state.system.loadingUploadProgress === 100) {
								store.commit("SET_IS_LOADINH_UPLOAD", false)
								this.state.system.loadingUploadProgress = 0
							}

						} else {
							handleErrMeg(res.msg)
						}
					})
				}

				await api.system.uploadFinish({
					md5: fileInfo.md5,
					fileName: fileInfo.fileName,
					category: payload.category
				})

				store.commit("CHANGE_DIALOGUE")
				store.dispatch("GET_ADMINTRAININGLIST", { currentIndex: 1, currentCategory: this.state.tableLayout.searchConditions.currentCategory })

			}

			api.system.fileFind(fileInfo)
				.then(res => {
					if (res.code === 0) {
						if (!res.data.file.isFinish) {
							const startIndex = res.data.file.sysFileChunks?.length
							postChunk(startIndex)
						} else {
							this.state.system.loadingUploadProgress = 100
							message.success(lanSetting.get("public", "uploadSuccess"))
							store.commit("SET_IS_LOADINH_UPLOAD", false)
							store.commit("CHANGE_DIALOGUE")
							store.dispatch("GET_ADMINTRAININGLIST", { currentIndex: 1, currentCategory: this.state.tableLayout.searchConditions.currentCategory })
						}


					} else {
						store.commit("SET_IS_LOADINH_UPLOAD", false)
						store.commit("CHANGE_DIALOGUE")
						store.dispatch("GET_ADMINTRAININGLIST", { currentIndex: 1, currentCategory: this.state.tableLayout.searchConditions.currentCategory })
					}
				})
				.catch(err => console.log(err))
		},
		["GET_NOTICE_LIST"](this: StoreT.Instance, injectee: StoreT.ActionContext, payload: StoreT.TableLayoutState["searchConditions"]) {
			// api.notice.getList(10, payload.currentIndex,payload.currentCategory as enumVal.PartnerStatusVal).then(res => {
			// 	store.commit("SET_TABLELAYOUT_LIST", {
			// 		list: res.data,
			// 		searchConditions: payload,
			// 	})
			// })
		},
		["DELETE_NOTICE"](this: StoreT.Instance, injectee: StoreT.ActionContext, payload: MainT.tableActionPayload) {
			actionModal({
				title: "Delete Notice",
				content: "Do you want to delete this item?",
				apiFunction: () => api.notice.delete(payload.record.record.ID),
				callback: () => store.dispatch("GET_NOTICE_LIST", { currentIndex: 1, currentCategory: this.state.tableLayout.searchConditions.currentCategory })
			})
		},
		["GET_PARTNER_MATERIALS_LIST"](this: StoreT.Instance, injectee: StoreT.ActionContext, payload: StoreT.TableLayoutState["searchConditions"]) {
			api.visitor.getMarketList(10, payload.currentIndex, payload.currentCategory as string).then(res => {
				if (res.code === 0) {
					store.commit("SET_TABLELAYOUT_LIST", {
						list: res.data,
						searchConditions: payload,
					})
				} else {
					handleErrMeg(res.msg)
				}
			})
		},
		["GET_PARTNER_TRAINING"](this: StoreT.Instance, injectee: StoreT.ActionContext, payload: StoreT.TableLayoutState["searchConditions"]) {
			api.visitor.getVisitorTrainingList(10, payload.currentIndex, payload.currentCategory as string).then(res => {
				if (res.code === 0) {
					store.commit("SET_TABLELAYOUT_LIST", {
						list: res.data,
						searchConditions: payload,
					})
				} else {
					handleErrMeg(res.msg)
				}
			})
		},
		["GET_STATISTICS"](this: StoreT.Instance, injectee: StoreT.ActionContext, payload: StoreT.TableLayoutState["searchConditions"]) {
			api.system.getStatistics(10, payload.currentIndex, true).then(res => {
				if (res.code === 0) {
					store.commit("SET_TABLELAYOUT_LIST", {
						list: res.data,
						searchConditions: payload,
					})
				} else {
					handleErrMeg(res.msg)
				}
			})
		},
		["GET_AGENT_LIST"](this: StoreT.Instance, injectee: StoreT.ActionContext, payload: StoreT.TableLayoutState["searchConditions"] & StoreT.TableLayoutState['selectConfigs']) {
			const { currentSelectConfig, currentIndex } = payload

			const { Manufacturer, Model } = currentSelectConfig
			api.agent.getAgentList({
				desc: false,
				keyword: "name",
				manufacturer: Manufacturer.select,
				model: Model.select,
				orderKey: "name",
				page: currentIndex,
				pageSize: 10
			}).then(res => {
				if (res.code === 0) {
					store.commit("SET_TABLELAYOUT_LIST", {
						list: res.data,
						searchConditions: payload,
					})
				} else {
					handleErrMeg(res.msg)
				}
			})
		},
		["GET_PARTNER_AGENT_LIST"](this: StoreT.Instance, injectee: StoreT.ActionContext, payload: StoreT.TableLayoutState["searchConditions"] & StoreT.TableLayoutState['selectConfigs']) {
			const { currentSelectConfig, currentIndex } = payload

			const { Manufacturer, Model } = currentSelectConfig
			api.agent.getPartnerAgentList({
				desc: false,
				keyword: "name",
				manufacturer: Manufacturer.select,
				model: Model.select,
				orderKey: "name",
				page: currentIndex,
				pageSize: 10
			}).then(res => {
				if (res.code === 0) {
					store.commit("SET_TABLELAYOUT_LIST", {
						list: res.data,
						searchConditions: payload,
					})
				} else {
					handleErrMeg(res.msg)
				}
			})
		},
		["GET_AETable_LIST"](this: StoreT.Instance, injectee: StoreT.ActionContext, payload: StoreT.TableLayoutState["searchConditions"] & StoreT.TableLayoutState['selectConfigs']) {
			const { currentIndex } = payload

			api.ae.getAEList({
				by: "created_at",
				order: "desc",
				page: currentIndex,
				size: 10
			}).then(res => {
				store.commit("SET_TABLELAYOUT_LIST", {
					list: res,
					searchConditions: payload
				})
			})
		},
		["GET_USER_INFO"](this: StoreT.Instance, injectee: StoreT.ActionContext) {
			api.ae.getUserInfo().then(res => {
				store.commit("SET_USER", res)
			})
		},
		async["GET_MANUFACTURER"](this: StoreT.Instance, injectee: StoreT.ActionContext) {
			let url: (() => Promise<Record<string, any>>) | undefined

			switch (this.state.system.currentPage?.page) {
				case "AdminAgent":
					url = api.agent.getAgentManufacturer
					break
				case "PartnerAgent":
					url = api.agent.getPartnerAgentManufacturer
					break
			}

			const res = url && await url()
			if (res) {
				if (res.code === 0) {

					const options = res.data.data?.map((item: any) => {
						return {
							text: item.name,
							value: item.name
						}
					}) ?? []
					store.commit("SET_AGENT_SELECT", res.data.data)
					store.commit("SET_MANUFACTURER", { options: options })
				} else {
					handleErrMeg(res.msg)
				}
			}
		},
		["GET_MODEL"](this: StoreT.Instance, injectee: StoreT.ActionContext, payload: { value: string | number }) {
			const agentSelect = this.state.tableLayout.agentSelect
			const options = agentSelect?.filter(item => item.name === payload.value)[0]?.models.map((item: any) => {
				return {
					text: item,
					value: item
				}
			}
			) ?? []
			store.commit("SET_MODEL", { options: options })
		},
		async["AGENT_UPLOAD"](this: StoreT.Instance, injectee: StoreT.ActionContext, payload: any) {

			const { state } = this
			const callback = async () => {
				const { selectConfigs } = state.tableLayout
				const { currentPage } = state.system

				store.commit("CHANGE_DIALOGUE")
				store.dispatch("GET_AGENT_LIST", {
					currentCategory: state.tableLayout.searchConditions.currentCategory,
					currentSelectConfig: selectConfigs[currentPage?.tableLayout || ""]
				})
				await store.dispatch("GET_MANUFACTURER")
				await store.dispatch("GET_MODEL", { value: selectConfigs[currentPage?.tableLayout || ""]?.Manufacturer.select })
			}

			if (this.state.form.tab === "create") {
				const _data = { ...payload }
				delete _data.file
				_data.fileId = _data.id
				_data.manufacturer = _data.manufacturer[0]?.replaceAll("，", ",")
				_data.model = _data.model[0]?.replaceAll("，", ",")
				_data.signature = _data.signature[0]
				api.agent.createAgent(_data)
					.then(res => {
						if (res.code === 0) {
							callback()
						} else {
							handleErrMeg(res.msg)
							store.commit("SET_IS_LOADINH_UPLOAD", false)
							callback()
						}
					})
			} else {
				const data = {
					category: payload.category,
					fileName: payload.fileName,
					id: payload.ID,
					fileId: payload.id || payload.fileId,
					manufacturer: payload.manufacturer[0],
					model: payload.model[0],
					name: payload.name,
					packageName: payload.packageName,
					signature: payload.signature,
					version: payload.version,
					remark: payload.remark
				}

				api.agent.upgrade(data)
					.then(res => {
						if (res.code === 0) {
							callback()
						} else {
							handleErrMeg(res.msg)
							store.commit("SET_IS_LOADINH_UPLOAD", false)
							callback()
						}
					})
			}
		},
		["AE_CREATE"](this: StoreT.Instance, injectee: StoreT.ActionContext, payload: any) {
			const { state } = this
			const { selectConfigs } = state.tableLayout
			const { currentPage } = state.system

			downloadFileByFetch(window.globalConfig.apiBase + `/project/generate?customer_name=${payload.customer_name}&domain=${payload.domain}`)
		}
	},
}
