import api from "@/api";
import { store } from "@/portal/store";
import { defineComponent, onMounted, ref } from "vue";
import "./VerificationCode.css"


const captcha = ref("")


export const getCaptcha = () => {
  api.login.getCaptcha().then(res => {
    captcha.value = res.data.picPath
    store.commit("SET_CAPTCHA_ID", res.data.captchaId)
  })
}

export default defineComponent({
  name: "VerificationCode",
  setup(props, ctx) {
    onMounted(() => {
      getCaptcha()
    })
    
    return { captcha }
  },
  render() {
    return (
      <div class="VerificationCode" onClick={() => getCaptcha()}><img src={this.captcha} alt="" /></div>
    )
  }
})