import { FormType } from "@/enums"
import { FormT } from "@/types"
import { customValidators, baseRuleValidators } from "@/helpers/tools";
import lanSetting from "@/helpers/lanHelper";

export default (() => ({
  form: {
    name: "loginForm",
    layout: "vertical",  
    formConfig: [
      {
        caption: "",
        dataIndex: "email",
        dataType: FormType.input,
        required: true,
        placeholder: lanSetting.get("public", "emailPlaceholder"),
        rule: () => [customValidators.email]
      },
      {
        caption: "",
        dataIndex: "password",
        dataType: FormType.password,
        required: true,
        placeholder: lanSetting.get("public", "passwordPlaceholder"),
        rule: () => [baseRuleValidators.min(6)]
      },
      {
        caption: "",
        dataIndex: "captcha",
        dataType: FormType.captcha,
        required: true,
        placeholder: lanSetting.get("public", "captchaPlaceholder"),
        rule: () => [baseRuleValidators.whitespace()]
      }
    ],
    submitText: lanSetting.get("public", "submitText"),
    submitWidth:"100%",
    hiddenCancelBtn: true
  }

})) as FormT.FormLayOutConfig