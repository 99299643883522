import { FormType } from "@/enums"
import { FormT } from "@/types"

export default (() => ({
  title: "Profile",
  form: {
    name: "profileForm",
    formConfig:
      [{
        caption: "First Name",
        dataIndex: "firstName",
        dataType: FormType.input
      }, {
        caption: "Last Name",
        dataIndex: "lastName",
        dataType: FormType.input
      }, {
        caption: "Company Name",
        dataIndex: "organizationName",
        dataType: FormType.input
      }, {
        caption: "Department",
        dataIndex: "department",
        dataType: FormType.input
      }, {
        caption: "Job Title",
        dataIndex: "jobTitle",
        dataType: FormType.input
      }, {
        caption: "Phone Number",
        dataIndex: "phone",
        dataType: FormType.input
      }]
  }

})) as FormT.FormLayOutConfig