import { FormType } from "@/enums"
import lanSetting from "@/helpers/lanHelper"
import { FormT } from "@/types"

export default (() => ({
  title: lanSetting.get("pageTree", "Home"),
  form: {
    name: "noticeBoard",
    hiddenCancelBtn: true,
    formConfig:
      [{
        caption: lanSetting.get("public", "title"),
        dataIndex: "title",
        required: true,
        dataType: FormType.input
      }, {
        caption: lanSetting.get("public", "content"),
        dataIndex: "content",
        required: true,
        dataType: FormType.textArea
      }]
  }

})) as FormT.FormLayOutConfig