import { store } from "@/portal/store"
import api from "@/api"
import { StoreT, FormT } from "@/types"
import { formNamesConfigs } from "@/portal/static/formConfig"
import { enumVal, FileRealType, Tab } from "@/enums"
import { getFormDefaultState } from "@/helpers/tools";
import lanSetting from "@/helpers/lanHelper"
import { message } from "ant-design-vue"
import { handleErrMeg } from "@/helpers/httpHelper"
import fileUploadHelper from "@/helpers/fileUploadHelper"

export const defaultState: StoreT.FormState = {
	formState: {},
	disabledAll: false,
	loading: false,
	tab: Tab.create
}

export default {
	state: JSON.parse(JSON.stringify(defaultState)),
	namespace: true,
	mutations: {
		["FORM_STATE_INIT"](state: StoreT.FormState, formName: string) {

			Object.assign(state.formState, {
				[formName]: getFormDefaultState(formNamesConfigs[formName] && formNamesConfigs[formName](state.tab, store.state).form.formConfig)
			})
			store.commit("CHANGE_DIALOGUE", formNamesConfigs[formName](state.tab, store.state))

			switch (formName) {
				case "accountForm": {
					store.dispatch("SET_FORM_STATE", { form: "accountForm", state: store.state.tableLayout.currentDetails })
				}
					break;
				case "adminAgentUploadForm": {
					store.dispatch("SET_FORM_STATE", { form: "adminAgentUploadForm", state: store.state.tableLayout.currentDetails })
				}
					break;
			}



		},
		["FORM_CHANGE"](state: StoreT.FormState, payload: { name: string, datatIndex: string, value: any }) {
			let { name, datatIndex, value } = payload

			switch(name) {
				case "adminAgentUploadForm": {
					if((datatIndex === "model" || datatIndex === "manufacturer" || datatIndex === "signature") && value.length > 1) {
						value = [value[1]]				
					}
					if(datatIndex === "manufacturer") {
						store.commit("FORM_CHANGE", {name, datatIndex: "model", value: []})
						store.commit("FORM_CHANGE", {name, datatIndex: "signature", value: []})
					}
				}
				break
			}

			delete state.formState[name].successMes?.[datatIndex]
			delete state.formState[name].errorMes?.[datatIndex]

			Object.assign(state.formState, {
				[name]: Object.assign(state.formState[name], {
					[datatIndex]: value
				})
			})
		},
		["FORM_STATE_DESTROY"](state: StoreT.FormState, payload: { name: string }) {
			const { name } = payload
			delete state.formState[name]
		},
		["FROM_SUBMIT"](state: StoreT.FormState, payload: any) {
			const { name, state: formData } = payload

			switch (name) {
				case "loginForm": {
					store.dispatch("LOGIN_SUBMIT", formData)
				}
					break;
				case "accountForm": {
					store.dispatch("ACCOUNT_SUBMIT", formData)
				}
					break;
				case "passwordForm": {
					store.dispatch("CHANGE_PASSWORD", formData)
					store.commit("CHANGE_DIALOGUE")
				}
					break;
				case "adminSalesInformationUploadForm": {
					store.dispatch("SALE_ADD", formData)
				}
					break;
				case "adminSalesMarketingUploadForm": {
					store.dispatch("MARKET_UPLOAD", formData)
				}
					break;
				case "adminTrainingUploadForm": {
					store.dispatch("TRAINING_UPLOAD", formData)
				}
					break;
				case "noticeBoard": {
					store.dispatch("NOTICE_ADD", formData)
				}
					break;
				case "forgotPasswordForm": {
					store.dispatch("FORGOT_PASSWORD", formData)
				}
					break;
				case "resetPasswordForm": {
					store.dispatch("RESET_PASSWORD", formData)
				}
					break
				case "profileForm": {
					store.dispatch("CHANGE_PROFILE", formData)
				}
					break
				case "adminAgentUploadForm": {
					store.dispatch("AGENT_UPLOAD", formData)
				}
					break
				case "aeCreateForm": {
					store.dispatch("AE_CREATE", formData)
				}
					break
			}
		},
		["CHANGE_FORM_TAB"](state: StoreT.FormState, payload: enumVal.TabVal) {
			state.tab = payload
			if (payload === Tab.detail && window.store.system.currentPage.tableLayout !== "agent") {
				state.disabledAll = true
			} else {
				state.disabledAll = false
			}

		}
	},
	actions: {
		["VERIFY_EMAIL"](this: StoreT.Instance, injecte: StoreT.ActionContext, payload: string) {
			if (payload) {
				api.system.verifyEmail(payload)
					.then(res => {
						if (res.code !== 0) {
							handleErrMeg(res.msg)
							this.state.form.formState.accountForm.errorMes = {
								email: lanSetting.get("customFormType", "verifyErrorEmail")
							}
							delete this.state.form.formState.accountForm.successMes.email
						} else {
							message.success(lanSetting.get("public", "sendSuccess"))
							this.state.form.formState.accountForm.successMes = {
								email: lanSetting.get("customFormType", "verifySuccessEmail")
							}
							delete this.state.form.formState.accountForm.errorMes.email

						}

					}, err => {

					})
			} else {
				this.state.form.formState.accountForm.errorMes = {
					email: lanSetting.get("customFormType", "verifyErrorEmailEmpty")
				}
			}

		},
		["SET_FORM_STATE"](this: StoreT.Instance, injecte: StoreT.ActionContext, payload: { form: string, state: Record<string, any> }) {
			this.state.form.formState[payload.form] = Object.assign({}, this.state.form.formState[payload.form], payload.state)
		},
		["NOTICE_ADD"](this: StoreT.Instance, injecte: StoreT.ActionContext, payload: FormT.FormPayload.noticeForm) {
			api.notice.add(payload).then(res => {
				if (res.code === 0) {
					message.success(lanSetting.get("public", "addSuccess"))
				} else {
					handleErrMeg(res.msg)
				}
			})
		},
		["FORGOT_PASSWORD"](this: StoreT.Instance, injecte: StoreT.ActionContext, payload: FormT.FormPayload.forgotPasswordForm) {
			this.state.form.loading = true
			api.login.forgotPassword(payload).then(res => {
				if (res.code === 0) {
					this.state.form.loading = false
					message.success(lanSetting.get("public", "sendSuccess"))
					// window.location.reload()
				} else {
					message.error(lanSetting.get("public", "errAccountNotApproved"))
					this.state.form.loading = false
				}
			})
		},
		["RESET_PASSWORD"](this: StoreT.Instance, injecte: StoreT.ActionContext, payload: FormT.FormPayload.resetPasswordForm) {
			let str = location.href.split("?key=")[1]
			let key = str.split("&random=")[0]
			let random = str.split("&random=")[1]
			api.login.resetPassword({ ...payload, key, random }).then(res => {
				if (res.code === 0) {
					this.commit("RESET_PASSWORD_IS_SUCESS", true)
				} else {
					handleErrMeg(res.msg)
				}
			})
		},
		["CHANGE_PROFILE"](this: StoreT.Instance, injecte: StoreT.ActionContext, payload: FormT.FormPayload.profileForm) {
			if (Object.values(payload).filter(item => item).length === 0) {
				message.warning(lanSetting.get("public", "changeProfile"))
			} else {
				let _payload: Record<string, any> = {}
				for (let key in payload) {
					if (payload[key] !== "") {
						_payload[key] = payload[key]
					}
				}
				api.visitor.changeProfile(_payload).then(res => {
					if (res.code === 0) {
						message.success(lanSetting.get("public", "editSuccess"))
						store.commit("CHANGE_DIALOGUE")

						setTimeout(() => {
							window.location.reload()
						}, 2000)
					} else {
						handleErrMeg(res.msg)
					}
				})
			}

		},
		async ["FORM_CHANGE_ACTIONS"](this: StoreT.Instance, injecte: StoreT.ActionContext, payload: { name: string, datatIndex: string, value: any }) {
			const { name, datatIndex, value } = payload
			if (name === "adminAgentUploadForm" && datatIndex === "file" && value[0]?.name.split(".")[ value[0]?.name.split(".").length - 1] === FileRealType.apk) {
				const errorMes = this.state.form.formState.adminAgentUploadForm.errorMes
				if(Object.keys(errorMes ?? {}).length < 1) {
					store.dispatch("CANCEL_AGENT")
				}

				store.commit("SET_IS_LOADINH_UPLOAD", true)
				store.commit("SET_LOADING_UPLOAD_PROGRESS", 0)
				const { chunks, fileInfo } = await fileUploadHelper(value[0]?.originFileObj)
				fileInfo.category = "agent"
				fileInfo.group = "agent"
				fileInfo.name = value[0]?.name

				const postChunk = async (startIndex: number) => {


					for (const [index, _chunk] of chunks.entries()) {
						if (index < startIndex) continue

						const formData = new FormData();

						formData.append('name', value[0]?.name);
						formData.append('category', "agent");
						formData.append('fileType', fileInfo.fileType);
						formData.append('fileName', fileInfo.fileName);
						formData.append('md5', fileInfo.md5);
						formData.append('chunkTotal', fileInfo.chunkTotal);
						formData.append('chunkMd5', _chunk.chunkMd5);
						formData.append('chunkNumber', String(index));
						formData.append('group', "agent");

						formData.append('file', _chunk.file);

						await api.agent.upload(formData).then(res => {
							if (res.code === 0) {

								// 进度条
								const process =  Math.round(((index + 1) / Number(fileInfo.chunkTotal)) * 100)
								store.commit("SET_LOADING_UPLOAD_PROGRESS", process)
								if (process === 100) {
									store.commit("SET_IS_LOADINH_UPLOAD", false)
								}

							} else {
								handleErrMeg(res.msg)
								store.commit("SET_IS_LOADINH_UPLOAD", false)
							}
						})
					}

					await api.agent.uploadFinish({
						md5: fileInfo.md5,
						fileName: fileInfo.fileName
					}).then(res => {
						if (res.code === 0) {
							Object.entries(res.data).map(item => {
								if(item[0] !== "category") {
									this.commit("FORM_CHANGE", {
										name,
										datatIndex: item[0],
										value: item[1]
									})
								}
							})
							const temp = fileInfo.fileName.split(".")

							const _fileName = temp.slice(0, temp.length - 1).join(".")

							const addChange = [["name", _fileName], ["fileName", fileInfo.fileName]]
							addChange.map(item => {
								this.commit("FORM_CHANGE", {
									name,
									datatIndex: item[0],
									value: item[1]
								})
							})
						}
					})
				}


				api.agent.fileFind(fileInfo)
					.then(res => {
						if (res.code === 0) {

							if(!res.data.packageName || !res.data.category || !res.data.version) {
								this.state.form.formState.adminAgentUploadForm.errorMes = {
									file: lanSetting.get("customFormType", "verifyErrorFileParsing")
								}
								
							}

							if(res.data.isUse) {
								this.state.form.formState.adminAgentUploadForm.errorMes = {
									file: lanSetting.get("customFormType", "verifyErrorFileExists")
								}
								
							}

							if (!res.data.isFinish) {
								delete this.state.form.formState.adminAgentUploadForm.errorMes?.file

								const startIndex = res.data.sysFileChunks?.length
								postChunk(startIndex)
							} else {
								store.commit("SET_LOADING_UPLOAD_PROGRESS", 100)
								message.success(lanSetting.get("public", "uploadSuccess"))
								store.commit("SET_IS_LOADINH_UPLOAD", false)
								Object.entries(res.data).filter(item => item[0] !== "isFinish" && item[0] !== "sysFileChunks" && item[0] !== "category").map(item => {
									this.commit("FORM_CHANGE", {
										name,
										datatIndex: item[0],
										value: item[1]
									})
								})

								const temp = fileInfo.fileName.split(".")

								const _fileName = temp.slice(0, temp.length - 1).join(".")

								const addChange = [["name", _fileName], ["fileName", fileInfo.fileName]]
								addChange.map(item => {
									this.commit("FORM_CHANGE", {
										name,
										datatIndex: item[0],
										value: item[1]
									})
								})
							}


						} else {
							handleErrMeg(res.msg)
							store.commit("SET_IS_LOADINH_UPLOAD", false)
						}
					})
					.catch(err => {
						store.commit("SET_IS_LOADINH_UPLOAD", false)
						console.log(err)
					})
			}
		},
		["CANCEL_AGENT"](this: StoreT.Instance, injecte: StoreT.ActionContext, payload: { name: string, datatIndex: string, value: any }) {
			const {id, errorMes} = this.state.form.formState.adminAgentUploadForm
			if(id && Object.keys(errorMes ?? {}).length < 1) {
				api.agent.cancel(id).then(res => {})
			}
			
		},
	},
}
