import { createRouter, createWebHashHistory, RouteRecordRaw, Router } from "vue-router"
import { store } from "@/portal/store"

const routerInfo = {
	router: null as Router | null,
}
export const initRouter = (routes: RouteRecordRaw[]) => {
	routerInfo.router = createRouter({
		history: createWebHashHistory(),
		routes: routes,
	})

	routerInfo.router.beforeEach((to, from, next) => {
		if (to.matched.length === 0) {
			from.name ? next({ name: from.name }) : next("/")
			return
		}
		store.dispatch("SWITCHPAGE", to)
		next()
	})
	return routerInfo.router
}

export default routerInfo
