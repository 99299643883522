import { FormType, Region } from "@/enums"
import lanSetting from "@/helpers/lanHelper"
import { FormT } from "@/types"
import { customValidators } from "@/helpers/tools";

export default (() => ({
  form: {
    name: "adminSalesInformationUploadForm",
    formConfig:
      [{
        caption: lanSetting.get("formConfig","name"),
        dataIndex: "name",
        required: true,
        dataType: FormType.input
      }, {
        caption: lanSetting.get("formConfig","email"),
        dataIndex: "email",
        required: true,
        rule: () => [customValidators.email],
        dataType: FormType.input
      }, {
        caption: lanSetting.get("formConfig","phone"),
        dataIndex: "phone",
        required: true,
        dataType: FormType.input
      }, {
        caption: lanSetting.get("formConfig","region"),
        dataIndex: "region",
        required: true,
        dataType: FormType.enum,
        enumParams: Region,
        defaultValue: Region.RegionAsia
      }]
  }

})) as FormT.FormLayOutConfig