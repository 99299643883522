import { store } from "@/portal/store"
import api from "@/api"
import { RouteLocationNormalized } from "vue-router"
import { StoreT, MainT, DataT, FormT } from "@/types"
import routerInfo from "@/portal/router"
import systemHelper from "@/helpers/systemHelper"
import formConfig, { formNamesConfigs } from "@/portal/static/formConfig"
import { enumVal, Tab, UserTypes, Category } from "@/enums"
import { getDefaultUserType } from "@/helpers/tools"
import { message } from "ant-design-vue"
import lanSetting from "@/helpers/lanHelper"
import { handleErrMeg } from "@/helpers/httpHelper"






export const defaultState: StoreT.SystemSate = {
	user: {},
	userType: getDefaultUserType() as enumVal.UserTypesVal,
	pagePaths: [],
	dialogueConfig: undefined,
	isLoadingUpload: false,
	loadingUploadProgress: 0
}
export default {
	state: defaultState,
	namespace: true,
	mutations: {
		["SWITCHPAGE"](state: StoreT.SystemSate, payload: RouteLocationNormalized) {
			state.currentPage = payload
		},
		["INITALIZE"](state: StoreT.SystemSate, payload: { pagePaths: MainT.PathConfig[] }) {
			state.pagePaths = payload.pagePaths
		},
		["SET_USER"](state: StoreT.SystemSate, payload: DataT.User) {
			state.user = payload
			if (payload.role === 999 || payload.role === 888) {
				state.userType = UserTypes.admin
			}
			if (payload.role === 1111) {
				state.userType = UserTypes.partner
			}
			if (payload.role === 0) {
				state.userType = UserTypes.null
			}

		},
		["SET_PARTNER_DASHBOARD"](state: StoreT.SystemSate, payload: StoreT.SystemSate["partnerDashboard"]) {
			state.partnerDashboard = Object.assign({}, state.partnerDashboard, payload)
		},
		["CHANGE_DIALOGUE"](state: StoreT.SystemSate, payload: StoreT.SystemSate["dialogueConfig"]) {
			state.dialogueConfig = payload
		},
		["CHANGE_USER_TYPE"](state: StoreT.SystemSate, payload: enumVal.UserTypesVal) {
			state.userType = payload
		},
		["FORM_CHANGE"](state: StoreT.SystemSate, payload: { name: string, datatIndex: string, value: any }) {
			let { name, datatIndex, value } = payload

			switch(name) {
				case "adminAgentUploadForm": {
					if((datatIndex === "model" || datatIndex === "manufacturer")) {
						if(value[0] !== store.state.form.formState[name][datatIndex]) {
							state.dialogueConfig = formNamesConfigs[name](store.state.form.tab, store.state)
						}	
					}
				}
				break
			}
		},
		["SET_LOADING_UPLOAD_PROGRESS"](state: StoreT.SystemSate, payload: number) {
			state.loadingUploadProgress = payload
		},
		["SET_IS_LOADINH_UPLOAD"](state: StoreT.SystemSate, payload: boolean) {
			state.isLoadingUpload = payload
		},
	},
	actions: {
		["INITALIZE"](this: StoreT.Instance, injecte: StoreT.ActionContext, payload: { pagePaths: MainT.PathConfig[] }) {
			store.commit("INITALIZE", payload)
		},
		["SWITCHPAGE"](this: StoreT.Instance, injecte: StoreT.ActionContext, payload: RouteLocationNormalized) {
			const { name, query } = payload,
				{ pagePaths } = this.state.system
			const target = systemHelper.getTargetPath(name as string, pagePaths)
			const category = query.currentCategory ?? ""
			store.commit("SWITCHPAGE", { ...target, ...payload })
			store.commit("CHANGE_CURRENT_CATEGORY", Number.isNaN(Number(category)) ? category : Number(category))
			if (target.tableLayout) {
				const _c = this.state.tableLayout.searchConditions.currentCategory
				const currentCategory = (_c !== "" && _c !== 0) ? _c : (target.tableLayout === "partner" ? 0 : "")
				store.commit("RESET_TABLELAYOUT_LIST")
				store.dispatch("GET_TABLELAYOUT_LIST", {
					currentCategory: currentCategory,
					currentIndex: 0,
				})
			}
			switch (target.page) {
				case "AdminDashBoard":
					store.dispatch("GET_STATISTIC")
					store.dispatch("GET_ACTIVE_REGION")
					store.dispatch("GET_LOGS")
					break

				case "PartnerHome": {
					store.dispatch("GET_FILE_NUM")
					setTimeout(() => {
						store.commit("SET_PARTNER_DASHBOARD", {
							quickAccess: [
								{ title: lanSetting.get("tableConfig", "productDecks"), currentCategory: Category.CategoryProductDecks },
								{ title: lanSetting.get("tableConfig", "CompanyDeck"), currentCategory: Category.CategoryCompanyDeck },
								{ title: lanSetting.get("tableConfig", "CaseStudy"), currentCategory: Category.CategoryCaseStudy },
								{ title: lanSetting.get("tableConfig", "Whitepapers"), currentCategory: Category.CategoryWhitepapers },
								{ title: lanSetting.get("tableConfig", "UserGuide"), currentCategory: Category.CategoryUserGuide },
								{ title: lanSetting.get("tableConfig", "Videos"), currentCategory: Category.CategoryVideos },
								{ title: lanSetting.get("tableConfig", "Others"), currentCategory: Category.CategoryOthers },
							],
							salePersion: {
								// name: this.state.system.user.firstName as string + this.state.system.user.lastName,
								// email: this.state.system.user.email,
								// phone: "string",

								//此处本阶段先写死
								name: "SafeUEM" + " " + "Sales",
								email: "sales@safeuem.com",
								phone: "123",
							},
						})
					}, 100)
					store.dispatch("GET_NOTICE_LAST")
				}
					break
				case "PartnerMarketingMaterials": {
					store.dispatch("GET_RECENTLY_UPDATE")
				}
					break
				default:
					break
			}
		},
		["NAVIGATE"](this: StoreT.Instance, injecte: StoreT.ActionContext, payload: { path: string; params: Record<string, string> }) {
			if (!routerInfo.router) return
			const { path, params } = payload
			path &&
				routerInfo.router.push({
					path: path,
					query: params || "",
				})
		},
		async["GET_USER"](this: StoreT.Instance, injectee: StoreT.ActionContext, payload: null) {
			if (location.href.indexOf("/resetpassword?key") > -1) {
				store.commit("SET_USER", { role: 0 })
			} else if (localStorage.getItem("token")) {
				const res = await api.user.get(localStorage.getItem("token") as string)
				if (res) {
					if (res.code !== 0) {
						handleErrMeg(res.msg)
						window.localStorage.removeItem("token")
						window.location.reload()
						// store.dispatch("SIGN_OUT")
					} else {
						store.commit("SET_USER", res.data)
					}
				}
			}
		},
		["SIGN_OUT"](this: StoreT.Instance, injectee: StoreT.ActionContext, payload: null) {
			api.ae.logout()
			.then(res => {
				location.href = "/#/login"
			})
			.catch(err => {
				location.href = "/#/login"
			})
		},
		["TO_CHANGE_DIALOGUE"](this: StoreT.Instance, injecte: StoreT.ActionContext, payload: string) {
			const _config = formConfig[payload]
			if (_config) {
				this.commit("CHANGE_DIALOGUE", _config(this.state.form.tab, this.state))
				return
			}
			if (payload) {
				this.commit("CHANGE_DIALOGUE", {
					title: "mesage",
					context: "hello"
				})
			}
		},
		["CHANGE_PASSWORD"](this: StoreT.Instance, injectee: StoreT.ActionContext, payload: FormT.FormPayload.passwordForm) {
			api.system.changePassword(payload).then(res => {
				if (res.code === 0) {
					message.success(lanSetting.get("public", "editSuccess"))
					this.dispatch("SIGN_OUT")
				} else {
					handleErrMeg(res.msg)
				}
			})
		},
		["GET_NOTICE_LAST"](this: StoreT.Instance, injectee: StoreT.ActionContext, payload: null) {
			api.notice.getLast().then(res => {
				if (res.code !== 0) {
					handleErrMeg(res.msg)
				} else {
					store.commit("SET_PARTNER_DASHBOARD", {
						notice: res.data,
					})
				}

			})
		},
		["GET_FILE_NUM"](this: StoreT.Instance, injectee: StoreT.ActionContext, payload: null) {
			api.visitor.downloaded().then(res => {
				if (res.code === 0) {
					store.commit("SET_PARTNER_DASHBOARD", {
						downloadedNum: res.data.total
					})
				} else {
					handleErrMeg(res.msg)
				}
			})

			api.visitor.fileResources().then(res => {
				if (res.code === 0) {
					store.commit("SET_PARTNER_DASHBOARD", {
						fileNum: res.data.total
					})
				} else {
					handleErrMeg(res.msg)
				}
			})
		}

	},
}
