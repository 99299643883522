import { InjectionKey, toRaw } from "vue"
import { createStore, useStore as _useStore } from "vuex"
import system from "./system"
import tableLayout from "./tableLayout"
import form from "./form"
import login from "./login"
import { StoreT } from "@/types"

export const key: InjectionKey<StoreT.Instance> = Symbol()

export const store = createStore<StoreT.State>({
	modules: { system, tableLayout, form, login },
	plugins: [
		(store) => {
			store.subscribe((mutation, state) => {
				console.info("%cmutation", "background:yellow;", mutation, toRaw(state))
			})
			store.subscribeAction((mutation, state) => {
				console.log("%caction", "background:pink;", mutation, toRaw(state))
			})
		},
	],
})

export const useStore = (): StoreT.Instance => {
	return _useStore(key)
}
