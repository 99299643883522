import adminHome from "./adminHome"
import partnerProfile from "./partnerProfile"
import password from "./password"
import { FormT } from "@/types"
import salesInfo from "./adminSalesInformationUpload"
import training from "./adminTrainingUpload"
import marketing from "./adminSalesMarketingUpload"
import account from "./account"
import login from "./login"
import forgotPassword from "./forgotPassword"
import resetPassword from "./resetPassword"
import agent from "./agent"
import ae from "./ae"


const configs: Record<string, FormT.FormLayOutConfig> = {
	AdminHome: adminHome,
	PartnerProfile: partnerProfile,
	password: password,
	salesInfo: salesInfo,
	training: training,
	marketing: marketing,
	account: account,
	login: login,
	forgotPassword: forgotPassword,
	resetPassword: resetPassword,
	agent: agent,
	ae: ae
}

export const formNamesConfigs = {
	[login().form.name]: login,
	[adminHome().form.name]: adminHome,
	[partnerProfile().form.name]: partnerProfile,
	[password().form.name]: password,
	[salesInfo().form.name]: salesInfo,
	[training().form.name]: training,
	[marketing().form.name]: marketing,
	[account().form.name]: account,
	[forgotPassword().form.name]: forgotPassword,
	[resetPassword().form.name]: resetPassword,
	[agent().form.name]: agent,
	[ae().form.name]: agent
}

export default configs
