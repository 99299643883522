import { FormType, Category } from "@/enums"
import { FormT } from "@/types"
import { customValidators } from "@/helpers/tools";
import lanSetting from "@/helpers/lanHelper"

export default (() => ({
  form: {
    name: "adminSalesMarketingUploadForm",
    formConfig:
      [{
        caption: lanSetting.get("formConfig", "name"),
        dataIndex: "name",
        required: true,
        dataType: FormType.input
      }, {
        caption: lanSetting.get("formConfig", "file"),
        dataIndex: "file",
        rule: () => [customValidators.emptyFile],
        required: true,
        dataType: FormType.file
      }, {
        caption: lanSetting.get("formConfig", "category"),
        dataIndex: "category",
        required: true,
        dataType: FormType.enum,
        enumParams: Category,
        defaultValue: Category.CategoryProductDecks
      }]
  }

})) as FormT.FormLayOutConfig