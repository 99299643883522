let SparkMD5: undefined | Awaited<ReturnType<typeof getBundle>>

const getBundle = async () => await import("spark-md5")
const getHash = (file: File | Blob) => {
	return new Promise<string>(async (resolve, reject) => {
		if (SparkMD5 === undefined) SparkMD5 = await getBundle()

		const spark = new SparkMD5.ArrayBuffer(),
			fileReader = new FileReader()

		fileReader.onload = (e) => {
			spark.append(e.target?.result as ArrayBuffer)
			resolve(spark.end())
		}
		fileReader.readAsArrayBuffer(file)
	})
}

export default (file: File, chunkSize = 40 * 1024 * 1024) => {
	return new Promise<{ chunks: { file: Blob, chunkMd5: string }[], fileInfo: Record<string, any> }>(async (resolve, reject) => {
		const hash = await getHash(file)

		const chunks = []
		let add = 0
		while (add < file.size) {
			const to = add + chunkSize
			const chunkFile = file.slice(add, to > file.size ? file.size : to)
			const chunkHash = await getHash(chunkFile)
			chunks.push({
				file: chunkFile,
				chunkMd5: chunkSize > file.size ? hash : chunkHash
			})

			// const name = `${hash}_${chunks.length + 1}_${add}_${to}`,


			add = to
		}

		const fileInfo = {
			md5: hash,
			fileName: file.name,
			fileType: file.name.split(".")[file.name.split(".").length - 1],
			chunkTotal: chunks.length,
			size: file.size
		}
		resolve({ chunks, fileInfo })
	})
}
