export default {
	"Afghanistan": "Afghanistan",
	"Åland": "Åland Islands",
	"Albania": "Albania",
	"Algeria": "Algeria",
	"American": "American Samoa",
	"Andorra": "Andorra",
	"Angola": "Angola",
	"Anguilla": "Anguilla",
	"Antarctica": "Antarctica",
	"Antigua": "Antigua and Barbuda",
	"Argentina": "Argentina",
	"Armenia": "Armenia",
	"Aruba": "Aruba",
	"Asia": "Asia/Pacific Region",
	"Australia": "Australia",
	"Austria": "Austria",
	"Azerbaijan": "Azerbaijan",
	"Bahamas": "Bahamas",
	"Bahrain": "Bahrain",
	"Bangladesh": "Bangladesh",
	"Barbados": "Barbados",
	"Belarus": "Belarus",
	"Belgium": "Belgium",
	"Belize": "Belize",
	"Benin": "Benin",
	"Bermuda": "Bermuda",
	"Bhutan": "Bhutan",
	"Bolivia": "Bolivia",
	"Bosnia": "Bosnia and Herzegovina",
	"Botswana": "Botswana",
	"Bouvet": "Bouvet Island",
	"Brazil": "Brazil",
	"BritishIndian": "British Indian Ocean Territory",
	"BritishVirgin": "British Virgin Islands",
	"Brunei": "Brunei",
	"Bulgaria": "Bulgaria",
	"Burkina": "Burkina Faso",
	"Burundi": "Burundi",
	"Cambodia": "Cambodia",
	"Cameroon": "Cameroon",
	"Canada": "Canada",
	"Cape": "Cape Verde",
	"Caribbean": "Caribbean Netherlands",
	"Cayman": "Cayman Islands",
	"Central": "Central African Republic",
	"Chad": "Chad",
	"Chile": "Chile",
	"China": "China",
	"Christmas": "Christmas Island",
	"Cocos": "Cocos (Keeling) Islands",
	"Colombia": "Colombia",
	"Comoros": "Comoros",
	"Congo": "Congo",
	"Cook": "Cook Islands",
	"Costa": "Costa Rica",
	"Cote": "Cote d'Ivoire",
	"Croatia": "Croatia",
	"Cuba": "Cuba",
	"Curaçao": "Curaçao",
	"Cyprus": "Cyprus",
	"Czech": "Czech Republic",
	"Democratic": "Democratic Republic of the Congo",
	"Denmark": "Denmark",
	"Djibouti": "Djibouti",
	"Dominica": "Dominica",
	"Dominican": "Dominican Republic",
	"East": "East Timor",
	"Ecuador": "Ecuador",
	"Egypt": "Egypt",
	"El": "El Salvador",
	"Equatorial": "Equatorial Guinea",
	"Eritrea": "Eritrea",
	"Estonia": "Estonia",
	"Ethiopia": "Ethiopia",
	"Europe": "Europe",
	"Falkland": "Falkland Islands",
	"Faroe": "Faroe Islands",
	"Fij": "Fij",
	"Finland": "Finland",
	"France": "France",
	"FrenchGuiana": "French Guiana",
	"FrenchPolynesia": "French Polynesia",
	"FrenchSouthern": "French Southern and Antarctic Lands",
	"Gabon": "Gabon",
	"Gambia": "Gambia",
	"Georgia": "Georgia",
	"Germany": "Germany",
	"Ghana": "Ghana",
	"Gibraltar": "Gibraltar",
	"Greece": "Greece",
	"Greenland": "Greenland",
	"Grenada": "Grenada",
	"Guadeloupe": "Guadeloupe",
	"Guam": "Guam",
	"Guatemala": "Guatemala",
	"Guernsey": "Guernsey",
	"Guinea": "Guinea",
	"GuineaBissau": "Guinea-Bissau",
	"Guyana": "Guyana",
	"Haiti": "Haiti",
	"Heard": "Heard Island and McDonald Islands",
	"Honduras": "Honduras",
	"Hong": "Hong Kong",
	"Hungary": "Hungary",
	"Iceland": "Iceland",
	"India": "India",
	"Indonesia": "Indonesia",
	"Iran": "Iran",
	"Iraq": "Iraq",
	"Ireland": "Ireland",
	"Isle": "Isle of Man",
	"Israel": "Israel",
	"Italy": "Italy",
	"Jamaica": "Jamaica",
	"Japan": "Japan",
	"Jersey": "Jersey",
	"Jordan": "Jordan",
	"Kazakhstan": "Kazakhstan",
	"Kenya": "Kenya",
	"Kiribati": "Kiribati",
	"Kuwait": "Kuwait",
	"Kyrgyzstan": "Kyrgyzstan",
	"Laos": "Laos",
	"Latvia": "Latvia",
	"Lebanon": "Lebanon",
	"Lesotho": "Lesotho",
	"Liberia": "Liberia",
	"Libya": "Libya",
	"Liechtenstein": "Liechtenstein",
	"Lithuania": "Lithuania",
	"Luxembourg": "Luxembourg",
	"Macau": "Macau",
	"Macedonia": "Macedonia (FYROM)",
	"Madagascar": "Madagascar",
	"Malawi": "Malawi",
	"Malaysia": "Malaysia",
	"Maldives": "Maldives",
	"Mali": "Mali",
	"Malta": "Malta",
	"Marshall": "Marshall Islands",
	"Martinique": "Martinique",
	"Mauritania": "Mauritania",
	"Mauritius": "Mauritius",
	"Mayotte": "Mayotte",
	"Mexico": "Mexico",
	"Micronesia": "Micronesia",
	"Moldova": "Moldova",
	"Monaco": "Monaco",
	"Mongolia": "Mongolia",
	"Montenegro": "Montenegro",
	"Montserrat": "Montserrat",
	"Morocco": "Morocco",
	"Mozambique": "Mozambique",
	"Myanmar": "Myanmar (Burma)",
	"Namibia": "Namibia",
	"Nauru": "Nauru",
	"Nepal": "Nepal",
	"Netherlands": "Netherlands",
	"NetherlandsAntilles": "Netherlands Antilles",
	"NewCaledonia": "New Caledonia",
	"NewZealand": "New Zealand",
	"Nicaragua": "Nicaragua",
	"Niger": "Niger",
	"Nigeria": "Nigeria",
	"Niue": "Niue",
	"Norfolk": "Norfolk Island",
	"North": "North Korea",
	"Northern": "Northern Mariana Islands",
	"Norway": "Norway",
	"Oman": "Oman",
	"Pakistan": "Pakistan",
	"Palau": "Palau",
	"Palestine": "Palestine",
	"Panama": "Panama",
	"Papua": "Papua New Guinea",
	"Paraguay": "Paraguay",
	"Peru": "Peru",
	"Philippines": "Philippines",
	"Pitcairn": "Pitcairn Islands",
	"Poland": "Poland",
	"Portugal": "Portugal",
	"Puerto": "Puerto Rico",
	"Qatar": "Qatar",
	"Réunion": "Réunion",
	"Romania": "Romania",
	"Russia": "Russia",
	"Rwanda": "Rwanda",
	"SaintBarthélemy": "Saint Barthélemy",
	"SaintHelena": "Saint Helena",
	"SaintKitts": "Saint Kitts and Nevis",
	"SaintLucia": "Saint Lucia",
	"SaintMartin": "Saint Martin",
	"SaintPierre": "Saint Pierre and Miquelon",
	"SaintVincent": "Saint Vincent and the Grenadines",
	"Samoa": "Samoa",
	"San": "San Marino",
	"Sao": "Sao Tome and Principe",
	"Saudi": "Saudi Arabia",
	"Senegal": "Senegal",
	"Serbia": "Serbia",
	"Seychelles": "Seychelles",
	"Sierra": "Sierra Leone",
	"Singapore": "Singapore",
	"Sint": "Sint Maarten",
	"Slovakia": "Slovakia",
	"Slovenia": "Slovenia",
	"Solomon": "Solomon Islands",
	"Somalia": "Somalia",
	"SouthAfrica": "South Africa",
	"SouthGeorgia": "South Georgia and the South Sandwich Islands",
	"SouthKorea": "South Korea",
	"SouthSudan": "South Sudan",
	"Spain": "Spain",
	"Sri": "Sri Lanka",
	"Sudan": "Sudan",
	"Suriname": "Suriname",
	"Svalbard": "Svalbard and Jan Mayen",
	"Swaziland": "Swaziland",
	"Sweden": "Sweden",
	"Switzerland": "Switzerland",
	"Syria": "Syria",
	"Taiwan": "Taiwan",
	"Tajikistan": "Tajikistan",
	"Tanzania": "Tanzania",
	"Thailand": "Thailand",
	"Togo": "Togo",
	"Tokelau": "Tokelau",
	"Tonga": "Tonga",
	"Trinidad": "Trinidad and Tobago",
	"Tunisia": "Tunisia",
	"Turkey": "Turkey",
	"Turkmenistan": "Turkmenistan",
	"Turks": "Turks and Caicos Islands",
	"Tuvalu": "Tuvalu",
	"U": "U.S. Virgin Islands",
	"Uganda": "Uganda",
	"Ukraine": "Ukraine",
	"UnitedArab": "United Arab Emirates",
	"UnitedKingdom": "United Kingdom",
	"UnitedStates": "United States",
	"UnitedMinor": "United States Minor Outlying Islands",
	"Uruguay": "Uruguay",
	"Uzbekistan": "Uzbekistan",
	"Vanuatu": "Vanuatu",
	"Vatican": "Vatican City",
	"Venezuela": "Venezuela",
	"Vietnam": "Vietnam",
	"Wallis": "Wallis and Futuna",
	"Western": "Western Sahara",
	"Yemen": "Yemen",
	"Zambia": "Zambia",
	"Zimbabwe": "Zimbabwe"
}
