import { FormType } from "@/enums"
import { FormT } from "@/types"
import { customValidators } from "@/helpers/tools";

export default (() => ({
  form: {
    name: "resetPasswordForm",
    formConfig: [
      {
        caption: "New Password",
        dataIndex: "newPassword",
        dataType: FormType.password,
        required: true,
        rule: () => [customValidators.passwordLength, customValidators.passwordRegexTrue, customValidators.passwordIncludeAccount, customValidators.passwordContinuous],
      },
      {
        caption: "Confirm New Password",
        dataIndex: "confirmPassword",
        dataType: FormType.password,
        required: true,
        rule: (formValue) => {
          return [customValidators.samePassword(formValue, "newPassword")]
        },
      }
    ],
    hiddenCancelBtn: true
  }

})) as FormT.FormLayOutConfig