import lanSetting from "@/helpers/lanHelper";
import { store } from "@/portal/store";
import { Spin } from "ant-design-vue"
import { LoadingOutlined } from "@ant-design/icons-vue";
import { Input } from "ant-design-vue";
import { InputProps } from "ant-design-vue/lib/input/inputProps";
import { h, ref } from "vue";
import "./VerifyEmail.css"


const NUMBER = 60

const disabled = ref(false), number = ref(NUMBER)

let timer: any = null, timerNumber: any = null

const indicator = h(LoadingOutlined, {
  style: {
    fontSize: '24px',
  },
  spin: true,
});

const render = (props: InputProps & { errorMes: string, successMes: string }) => {

  if (props.errorMes || props.successMes) {
    onBeforeUnmount()
  }

  return (
    <div class="VerifyEmailBox">
      <Input {...props} disabled={disabled.value} />
      <div
        class={timer || !props.value ? "VerifyEmailText timer" : "VerifyEmailText"}
        onClick={() => {
          if (timer || !props.value) return

          disabled.value = true

          timerNumber = setInterval(() => {
            number.value -= 1
          }, 1000 * 1)

          store.dispatch("VERIFY_EMAIL", props.value)

          if (!timer) {
            timer = setTimeout(() => {
              onBeforeUnmount()
            }, 1000 * NUMBER)
          }

        }}
      >
        {timer && <Spin indicator={indicator}></Spin>}
        {timerNumber && <span>{number.value}</span>}
        {!timer && !timerNumber && lanSetting.get("customFormType", "verify")}
      </div>
    </div>
  )
}

const onBeforeUnmount = () => {
  disabled.value = false

  clearTimeout(timer)
  timer = null
  clearInterval(timerNumber)
  timerNumber = null

  number.value = NUMBER
}

export default () => { return { render, onBeforeUnmount } }