import { FormType, Region, Tab } from "@/enums"
import { FormT } from "@/types"
import { customValidators } from "@/helpers/tools";
import customFormType from "../customFormType";
import lanSetting from "@/helpers/lanHelper";
import Country from "@/enums/Country";

export default (() => ({
  form: {
    name: "accountForm",
    layout: "inline",  
    useStoreFormState: true,
    formConfig: [
      {
        caption: lanSetting.get("login","firstName"),
        dataIndex: "firstName",
        dataType: FormType.input,
        required: true,
        width: "47%"
      },
      {
        caption: lanSetting.get("login","lastName"),
        dataIndex: "lastName",
        dataType: FormType.input,
        required: true,
        width: "47%"
      },
      {
        caption: lanSetting.get("login","email"),
        dataIndex: "email",
        dataType: "custom",
        required: true,
        width: "47%",
        hidden: Tab.detail,
        custom: customFormType.verifyEmail,
        rule: () => [customValidators.email]
      },
      {
        caption: lanSetting.get("login","email"),
        dataIndex: "email",
        dataType: FormType.input,
        required: true,
        width: "47%",
        hidden: Tab.create,
        rule: () => [customValidators.email]
      },
      {
        caption: lanSetting.get("login","verificationCode"),
        dataIndex: "verificationCode",
        dataType: FormType.input,
        required: true,
        width: "47%"
      },
      {
        caption: lanSetting.get("login","phone"),
        dataIndex: "phone",
        dataType: FormType.input,
        required: true,
        width: "47%"
      },
      {
        caption: lanSetting.get("login","jobTitle"),
        dataIndex: "jobTitle",
        dataType: FormType.input,
        required: true,
        width: "47%"
      },
      {
        caption: lanSetting.get("login","department"),
        dataIndex: "department",
        dataType: FormType.input,
        required: true,
        width: "47%"
      },
      {
        caption: lanSetting.get("login","organizationName"),
        dataIndex: "organizationName",
        dataType: FormType.input,
        required: true,
        width: "47%"
      },
      {
        caption: lanSetting.get("login","region"),
        dataIndex: "region",
        dataType: FormType.enum,
        required: true,
        enumParams: Region,
        defaultValue: Region.RegionAsia,
        width: "47%"
      },
      {
        caption: lanSetting.get("login","country"),
        dataIndex: "country",
        dataType: FormType.enum,
        required: true,
        enumParams: Country,
        defaultValue:  Country.China,
        width: "47%"
      },
      {
        caption: lanSetting.get("login","postalCode"),
        dataIndex: "postalCode",
        dataType: FormType.input,
        width: "47%"
      },
      {
        caption: lanSetting.get("login","partnershipInterest"),
        dataIndex: "partnershipInterest",
        dataType: FormType.input,
        width: "47%"
      },
      {
        caption: lanSetting.get("login","industryFocus"),
        dataIndex: "industryFocus",
        dataType: FormType.input,
        width: "47%"
      },
      {
        caption: lanSetting.get("login","content"),
        dataIndex: "content",
        dataType: FormType.textArea,
        width: "100%"
      },
      {
        caption: "",
        dataIndex: "captcha",
        dataType: FormType.captcha,
        required: true,
        placeholder: lanSetting.get("public","captchaPlaceholder"),
        width: "70%",
        hidden: Tab.detail
      },
      {
        caption: "",
        dataIndex: "terms",
        dataType: FormType.checkbox,
        width: "100%",
        required:true,
        rule: () => [customValidators.checkboxRequired],
        hidden: Tab.detail,
        checkboxText: lanSetting.get("login","terms"),
      }
    ],
    submitWidth:"47%",
    hiddenCancelBtn: true
  }

})) as FormT.FormLayOutConfig