import { FormType } from "@/enums"
import lanSetting from "@/helpers/lanHelper"
import { FormT } from "@/types"

export default (() => ({
  form: {
    name: "forgotPasswordForm",
    formConfig: [
      {
        caption: "",
        dataIndex: "email",
        dataType: FormType.input,
        required: true,
        placeholder: lanSetting.get("public","emailPlaceholder")
      },
      // {
      //   // caption: "",
      //   // dataIndex: "static",
      //   // dataType: FormType.custom,
      //   // custom: <div
      //   //   style={{
      //   //     fontWeight: 500,
      //   //     marginTop: "-2rem",
      //   //     color: "rgba(227, 77, 89, 1)"
      //   //   }}>
      //   //   <svg class="icon" aria-hidden="true" style={{fontSize: "1.6rem" }}> <use xlinkHref="#icon-Shapex"></use> </svg>
      //   //   <span style={{marginLeft:"0.5rem"}}>This account has not been approved!</span>
      //   // </div>
      // },
      // {
      //   caption: "",
      //   dataIndex: "static",
      //   dataType: FormType.custom,
      //   // custom: <div
      //   //   style={{
      //   //     textAlign: "right",
      //   //     fontWeight: 500
      //   //   }}>
      //   //   Go back to <a onClick={() => props.changeShowContent("login")}>login</a>
      //   // </div>
      // }
    ],
    hiddenCancelBtn: true
  }

})) as FormT.FormLayOutConfig